/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"development"} description={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap name={"q13ep9ji9wi"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"lgz2ao2y4fp"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"ih076rdtikh"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Atypické řešení"}>
              </Title>

              <Text className="text-box" content={"Dnešní trh nabízí mnoho produktů které splňují většinu potřeb zákazníku. Občas jsou požadavky mimo možnosti standartních výrobků. V takovém případě nastupuje na řadu vývoj. A právě&nbsp; díky dlouholetým zkušenostem z oblasti mechanického zabezpečení, vývoje elektronických vstupních systému a software/firmware jsme Vám schopni nabídnout atypické řešení Vašich požadavků. Pokud žádný z produktů na trhu nesplňuje Vaše požadavky neváhejte nás kontaktovat. Váš požadavek vyřešíme buďto kombinací již existujících zařízení a řešení, nebo navrhneme řešení nové které trh nenabízí.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}